@import url('https://fonts.googleapis.com/css?family=Prompt:300,400&display=swap');
@import '~bootstrap/scss/bootstrap-reboot.scss';
@import '~bootstrap/scss/bootstrap-grid.scss';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/alert';

$primary: #F4A73F;
$dark: #2D2D2D;

.primary-color {
  color: $primary;
}

body {
  margin: 0;
  font-family: 'Prompt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: whitesmoke;
}

a {
  color: $primary;
}
a:hover {
  color: lighten($primary, 10);
}
a:visited {
  color: darken($primary, 5);
}

@import 'scss/app.scss';
