.wrapper {
  text-align: center;
}
.current-version {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: .25rem;
  font-size: .5rem;
  opacity: .2;
}
.update-alert {
  position: fixed;
  bottom: 0;
  left: 1rem;
  right: 1rem;
}

.header-wrapper {
  background-color: $dark;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(16px + 2vmin);
  .profile {
    .avatar {
      position: relative;
      margin: 3rem auto 0 auto;
      width: 12rem;
      height: 12rem;
      border: 4px solid $primary;
      border-radius: 50%;
      background: $dark;
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        opacity: .8;
      }
    }
    .name {
      margin: 1rem 0;
      color: white;
    }
  }
  .personal {
    margin-bottom: 2.5rem;
    font-size: 1rem;
    color: white;
    h6 {
      font-size: 1.25rem;
      text-transform: uppercase;
      text-decoration: underline;
    }
    p {
      text-align: left;
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        font-size: 1.125rem;
        text-align: left;
      }
    }
  }
}
.content-wrapper {
  margin: 0 auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
  .skill-container {
    display: flex;
    flex-direction: column;
    h6 {
      font-size: 1.25rem;
      color: $primary;
      text-transform: uppercase;
      text-decoration: underline;
    }
    .skills {
      list-style: none;
      padding: 0;
      .skill-item {
        padding: .5rem 0;
        .skill-label {
          font-weight: bold;
        }
      }
    }
    .experiences {
      list-style: none;
      padding: 0;
      margin-bottom: 2rem;
      .experience-item {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: .5rem 0;
        .year {
          position: relative;
          margin-bottom: 1rem;
          .label {
            margin: 0 auto;
            width: 50px;
            height: 50px;
            padding-top: 12px;
            border-radius: 50%;
            border: 2px solid $primary;
            &.present {
              width: 80px;
              padding: 4px 0;
              border-radius: 8px;
              height: auto;
            }
          }
        }
        .detail {
          position: relative;
          padding: .5rem;
          border: 2px solid $primary;
          border-radius: 8px;
          text-align: left;
          .label {
            margin: 0 0 .25rem 0;
            text-transform: uppercase;
            &.text-bold {
              font-weight: bold;
            }
            .sub-label {
              font-size: 1rem;
              font-weight: normal;
              text-transform: none;
            }
          }
          &:before {
            display: block;
            content: "";
            position: absolute;
            left: 50%;
            top: -18px;
            height: 16px;
            border-left: 2px solid $primary;
          }
          &:after {
            display: block;
            content: "";
            position: absolute;
            left: 50%;
            bottom: -18px;
            height: 16px;
            border-left: 2px solid $primary;
          }
        }
        &:last-child {
          .detail {
            &:after {
              display: none;
            }
          }
        }
      }
    }
    .characteristics {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      list-style: none;
      padding: 0;
      .item {
        padding-bottom: 1rem;
      }
    }
    .interests {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      list-style: none;
      padding: 0;
      .item {
        width: 30%;
        padding-bottom: 1rem;
        .badge {
          margin: .25rem auto;
          padding: .275rem .25rem .25rem;
          width: 48px;
          height: 48px;
          border: 2px solid $primary;
          border-radius: 50%;
          background-color: white;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        .label {
          margin: .25rem auto;
          text-transform: uppercase;
          font-size: .75rem;
        }
      }
    }
  }
  .exp-bar {
    display: flex;
    justify-content: space-between;
    margin: 8px auto;
    max-width: 200px;
    .exp-point {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      border: 1px solid $primary;
      &.filled {
        background-color: $primary;
      }
    }
  }
  .progress-bar {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 6px;
    margin: 8px auto;
    max-width: 200px;
    .total,
    .progression {
      height: 6px;
      position: absolute;
      top: 0;
      left: 0;
    }
    .total {
      width: 100%;
      background-color: #B7B7B7;
    }
    .progression {
      background-color: $primary;
      z-index: 1;
    }
  }
}

/*
 * For tablet portrait up
 */
@media (min-width: 600px) {
  .update-alert {
    left: 50%;
    transform: translate(-50%, 0);
  }
}

/*
 * For tablet landscape up
 */
@media (min-width: 900px) {
  .header-wrapper {
    .profile {
      display: flex;
      margin: 2rem 0 3rem 0;
      align-items: center;
      .avatar {
        margin: 0;
      }
      .name {
        margin: .5rem 3rem;
        text-align: left;
      }
    }
    .personal {
      display: flex;
      justify-content: space-between;
      h6 {
        text-align: left;
      }
      .about {
        width: 25%;
      }
      .biological {
        width: 35%;
      }
      .contact {
        width: 35%;
      }
    }
  }
  .content-wrapper {
    .skill-container {
      flex-direction: row;
      h6 {
        text-align: left;
      }
      .skills {
        .skill-item {
          text-align: left;
        }
      }
      .experiences {
        list-style: none;
        padding: 0;
        .experience-item {
          flex-direction: row;
          .year {
            width: 120px;
            margin-bottom: 0;
            .label {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              &:before {

              }
              &:after {

              }
            }
          }
          .detail {
            width: calc(100% - 120px);
            &:before {
              display: block;
              content: "";
              position: absolute;
              top: 50%;
              left: -38px;
              width: 38px;
              height: 0;
              border-top: 2px solid $primary;
            }
            &:after {
              display: none;
            }
            &.present {
              &:before {
                left: -24px;
                width: 24px;
              }
            }
          }
        }
      }
      .characteristics {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .item {
          text-align: left;
          width: 30%;
        }
      }
      .interests {
        .item {
          width: 15%;
        }
      }
    }
    .exp-bar {
      margin: 8px 0;
    }
    .progress-bar {
      max-width: 100%;
    }
  }
}

/*
 * For normal desktop screen up
 */
@media (min-width: 1200px) {

}

/*
 * For FHD screen up
 */
@media (min-width: 1800px) {

}
